<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Select Vendor</label>
              <bulma-autocomplete v-model="vendorFilter.name"
                :data="vendors"
                field="value"
                @typing="searchVendor"
                @select="selectVendor"
                :keep-first="true"
                :loading="isVendorLoading"
                :expanded="true"
                :type="innerSelectedVendor ? '' : 'is-danger'" />
            </div>
            <div class="field is-narrow">
              <label class="label">&nbsp;</label>
              <div class="control">
                <input class="is-checkradio is-primary"
                  :class="{ 'has-background-color': isShowPartNo }"
                  id="show-part-no"
                  type="checkbox"
                  name="show-part-no"
                  v-model="isShowPartNo">
                <label for="show-part-no">Show Part No.</label>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="filter.pageIndex"
            :page-size="filter.pageSize"
            :total-rows="totalRows"
            :is-loading="isTableLoading"
            :sort-column="filter.sortColumn"
            :sort-order="filter.sortOrder"
            :show-pagination="false"
            :show-header="true"
            :is-title-checkable="true"
            @pageChanged="onPageChange"
            @sort="sort"
            @title-checkbox-changed="titleCheckboxChanged">
            <tr v-for="(item, index) in items"
              :key="item.quoteItemId">
              <td>{{ item.itemNo }}</td>
              <td>{{ item.itemDesc }}</td>
              <td class="has-text-centered">
                <span class="tag"
                  :class="sideTagClass(item.side)">
                  {{ item.side }}
                </span>
              </td>
              <td class="has-text-centered">{{ item.mark | formatPartType }}</td>
              <td>{{ item.itemQuantity }}</td>
              <td class="has-text-right">{{ item.value | formatNumber($userInfo.locale) }}</td>
              <td>{{ item.partNo }}</td>
              <td class="has-text-centered has-vertical-middle is-content-width">
                <div class="field">
                  <div class="control">
                    <input class="is-checkradio is-primary"
                      :class="{ 'has-background-color': item.isSelected }"
                      :id="`parts-enquiry-select${index}`"
                      :name="`parts-enquiry-select${index}`"
                      type="checkbox"
                      v-model="item.isSelected">
                    <label :for="`parts-enquiry-select${index}`"
                      data-label-empty />
                  </div>
                </div>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { PartsEnquiryColumns } from '../columns'
import { PartTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import VendorService from '@/views/vendor/VendorService'
import _debounce from 'lodash.debounce'
import _orderBy from 'lodash/orderBy'
import _cloneDeep from 'lodash/cloneDeep'
// import Guid from '@/components/Guid'

export default {
  name: 'PartsEnquiry',
  components: {
    BulmaTable,
    BulmaAutocomplete
  },
  filters: {
    formatPartType(value) {
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    selectedItems: {
      type: Array,
      default: () => []
    },
    selectedVendor: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isTableLoading: false,
      isVendorLoading: false,
      filter: {
        sortColumn: PartsEnquiryColumns[7].name,
        sortOrder: PartsEnquiryColumns[7].asc,
        pageIndex: 1,
        pageSize: 100
      },
      vendorFilter: {
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      vendors: [],
      innerSelectedVendor: null,
      items: null,
      totalRows: 0,
      // selected: null,
      // selectedParts: null,
      isShowPartNo: false
    }
  },
  computed: {
    columns() {
      return PartsEnquiryColumns
    },
    selected() {
      if (this.items) {
        return this.items.map(function (i) {
          return i.isSelected
        })
      }
    }
  },
  watch: {
    // selected(newVal, oldVal) {
    //   const vm = this
    //   this.selectedParts = this.items
    //     .filter(function(item, index) {
    //       return vm.selected[index]
    //     })
    //     .map(function(i) {
    //       return {
    //         itemDesc: i.itemDesc,
    //         partNo: vm.isShowPartNo ? i.partNo : '',
    //         mark: i.mark
    //       }
    //     })
    //   this.$emit('update:selectedItems', this.selectedParts)
    // },
    // items: {
    //   handler: function(newVal, oldVal) {
    //     if (!oldVal) {
    //       return
    //     }
    //     const vm = this
    //     const selected = this.items
    //       .filter(i => i.isSelected)
    //       .map(function(i) {
    //         return {
    //           itemDesc: i.itemDesc,
    //           partNo: vm.isShowPartNo ? i.partNo : '',
    //           mark: i.mark
    //         }
    //       })
    //     // console.log(selected)
    //     this.$emit('update:selectedItems', selected)
    //   },
    //   deep: true
    // }
    selected(newVal, oldVal) {
      const vm = this
      const selected = this.items
        .filter((i, index) => vm.selected[index])
        .map(function (i) {
          return {
            itemDesc: i.itemDesc,
            partNo: vm.isShowPartNo ? i.partNo : '',
            mark: i.mark,
            sortNo: i.sortNo
          }
        })
      this.$emit('update:selectedItems', selected)
    }
  },
  created() {
    this.items = _cloneDeep(
      _orderBy(
        this.value.filter((i) => !i.isNew && !i.deleted && !i.reportOnly),
        [this.filter.sortColumn],
        [this.filter.sortOrder]
      )
    )
    this.totalRows = this.value.filter((i) => !i.isNew && !i.deleted && !i.reportOnly).length
    // this.selected = new Array(this.totalRows).fill(false)
  },
  mounted() {},
  methods: {
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.items = _cloneDeep(
        _orderBy(
          this.value.filter((i) => !i.isNew && !i.deleted && !i.reportOnly),
          [this.filter.sortColumn],
          [this.filter.sortOrder]
        )
      )
    },
    sideTagClass(side) {
      let tagClass = ''
      switch (side) {
        case 'O':
          tagClass = 'is-light'
          break
        case 'R':
          tagClass = 'is-primary'
          break
        case 'L':
          tagClass = 'is-info'
          break
      }
      return tagClass
    },
    titleCheckboxChanged(value) {
      // this.selected = new Array(this.totalRows).fill(value)
      this.items.forEach((i) => (i.isSelected = value))
    },
    getVendorsDropdown: async function () {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto(this.vendorFilter)
      this.isVendorLoading = false
    },
    searchVendor: _debounce(function (val) {
      if (val === '') {
        return
      }
      this.getVendorsDropdown()
    }, 300),
    async selectVendor(option) {
      this.innerSelectedVendor = option
      const vendor = await VendorService.getEntity(this.innerSelectedVendor.key)
      const email = vendor.emails.length > 0 ? vendor.emails.find((e) => e.isDefault) || vendor.emails[0] : null
      this.$emit('update:selectedVendor', {
        vendorId: vendor.id,
        email: email && email.address ? email.address.trim() : ''
      })
    }
  }
}
</script>
